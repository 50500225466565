import "./RegisterComponent.css";
import React, { useState } from "react";
import {
    EyeInvisibleOutlined,
    EyeTwoTone,
    LeftOutlined,
    LockFilled,
    MailOutlined,
    UserOutlined,
    IdcardOutlined

} from "@ant-design/icons";
import {Link, useNavigate} from "react-router-dom";
import {Button, Input, Alert, message} from "antd";
import apiUser from "../../Connexion/AxiosUserMS";


function RegisterComponent() {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [profession, setProfession] = useState("")
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const handleReturn = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const success = () => {
        message.open({
            type: 'success',
            content: 'Register successful !! ',
        });
    };

    const handleRegister = async () => {
        if (password !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        try {
            const response = await apiUser.post("/signup",
                { email, password, fullName, profession });

            const userData = response.data;
            console.log("Response from signup API:", userData);

            if (userData && userData.id) {
                console.log("User Infos:", {
                    id: userData.id,
                    fullName: userData.fullName,
                    email: userData.email,
                    profession: userData.profession
                });
            }
            success();
            navigate("/login");
        } catch (error) {
            console.error("Error during registration:", error);
            setError(error.response.data.message || "An error occurred during registration");
        }
    };

    return (
        <div id="register-component">
            <Link to="/home" onClick={handleReturn} className="return-container"><LeftOutlined style={{ fontSize: 30, marginRight: 7,color:"#0062BD" }} />
                Return
            </Link>

            <div className='title_card'>
                <h2>Generic PortFolio</h2>
                <p>The best plateform to showcase your work and skills</p>
            </div>

            <div className='ellipse_component' style={{maxWidth:700,maxHeight:700}}>
                <div className='login_form' style={{maxWidth:620,maxHeight:620}}>
                    <div className="st" >
                        <h3 >Register</h3>
                        <div className="signUp"><strong>Already have an account? </strong>
                            <Link to="/login" style={{marginLeft:10 , color:'#2090F8',textDecoration: "none"}}>Login here </Link>
                        </div>
                        <br/>
                        <br/>

                    </div>
                    <Input size="middle"
                           placeholder="   Your full name"
                           bordered={false}
                           prefix={<UserOutlined />}
                           onChange={(e) => setFullName(e.target.value)}
                           style={{width:400,height:35,
                               borderBottom: '1px solid black'}}
                           className="c-input"
                    />

                    <br/>
                    <Input size="middle"
                           placeholder="   Your profession"
                           bordered={false}
                           prefix={<IdcardOutlined />}
                           onChange={(e) => setProfession(e.target.value)}
                           style={{width:400,height:35,
                               borderBottom: '1px solid black'}}
                           className="c-input"
                    />
                    <br/>

                    <Input size="middle"
                           placeholder="   Your mail address"
                           bordered={false}
                           prefix={<MailOutlined />}
                           onChange={(e) => setEmail(e.target.value)}
                           style={{width:400,height:35,
                               borderBottom: '1px solid black'}}
                           className="c-input"
                    />
                    <br/>
                    <Input.Password
                        size="large"
                        bordered={false}
                        placeholder="   Your password"
                        prefix={<LockFilled />}
                        onChange={(e) => setPassword(e.target.value)}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        style={{
                            borderBottom: '1px solid black',
                            width:400,height:35}}
                        className="c-input"
                    />
                    <br/>
                    <Input.Password
                        size="large"
                        bordered={false}
                        placeholder="   Confirm your password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        prefix={<LockFilled />}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        style={{
                            borderBottom: '1px solid black',
                            width:400,height:35}}
                        className="c-input"
                    />
                    <br/>
                    <br/>
                    {error && <Alert message={error} type="error" showIcon closable afterClose={() => setError("")} />}
                    <Button type="primary" block
                            style={{width:400, height:38, fontSize:20, fontFamily:"Montserrat", marginLeft:40}}
                            className="btn-l"
                            onClick={handleRegister}
                    > Register</Button>
                    <br/>
                    <br/>
                </div>
            </div>
        </div>
    );
};

export default RegisterComponent;