import HomePageComponent from "./Components/HomePageComponent/HomePageComponent";
import {Route, Routes} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AboutUsComponent from "./Components/AboutUsComponent/AboutUsComponent";
import TalentsComponent from "./Components/TalentsComponent/TalentsComponent";
import LoginComponent from "./Components/LoginComponent/LoginComponent";
import RegisterComponent from "./Components/RegisterComponent/RegisterComponent";
import PortfolioCreation from "./Components/PortfolioComponent/PortfolioCreation";
import ProfilePage from "./Components/ProfileComponent/ProfilePage";
import ShowPortfolio from "./Components/PortfolioComponent/ShowPortfolio_steps/Portfolio";
import {AuthProvider} from "./AuthenticationContext/AuthContext";
import InputPDFComponent from "./Components/PortfolioComponent/ShowPortfolio_IA/ChatInputPDFComponent";
import ShowPortfolioAi from "./Components/PortfolioComponent/ShowPortfolio_IA/Portfolio";
import {ButtonClickProvider} from "./Components/ProfileComponent/ButtonClickContext";


function App() {
  return (
      <AuthProvider>
          <ButtonClickProvider>
    <div className="mb-3 app_color ">
      <Routes>
          <Route path="/" element={<HomePageComponent/>} />
          <Route path="/home" element={<HomePageComponent/>} />
          <Route path="/aboutUs" element={<AboutUsComponent/>}/>
          <Route path="/talentPool" element={<TalentsComponent/>}/>
          <Route path="/login" element={<LoginComponent/>}/>
          <Route path="/register" element={<RegisterComponent/>}/>
          <Route path="/portfolio-creation" element={<PortfolioCreation/>} />
          <Route path="/my-profile" element={<ProfilePage/>} />
          <Route path="/my-portfolio" element={<ShowPortfolio/>} />
          <Route path="/resume-upload" element={<InputPDFComponent/>} />
          <Route path="/ai-portfolio" element={<ShowPortfolioAi/>} />
      </Routes>
    </div>
          </ButtonClickProvider>
      </AuthProvider>
  );
}

export default App;
