import { Button, Divider } from "antd";
import './portfolio_steps.css';
import HeaderComp from "./HeaderComp";
import FooterComp from "./FooterComp";
import AsideComp from "./AsideComp";
import {useEffect, useState} from "react";
import {useAuth} from "../../../AuthenticationContext/AuthContext";
import axios from "axios";
import {fetchPortfolioData} from "../../../Connexion/axiosPortfolio";


function ShowPortfolio() {
    const { user } = useAuth();
    const [portfolioData, setPortfolioData] = useState({
        headline: "",
        projets: [],
    });

    useEffect(() => {
        const fetchPortfolio = async () => {
            try {
                const userId = user ? user.id : null;
                console.log("userId test: ", userId);
                const data = await fetchPortfolioData(userId);
                setPortfolioData(data);
                console.log("data from API: ", data);
            } catch (error) {
                console.error('Error fetching portfolio data:', error);
            }
        };

        fetchPortfolio();
    }, [user]);

    return (
        <>
            <div className='main'>
                <HeaderComp />
                <div className='body-container'>
                    <div className='left'>
                        <section className='about'>
                            <article>
                                <h2 style={{ color: "white" }}>About me</h2><br/>
                                <p style={{ color: "white",textAlign:"center",fontSize:24 }}>
                                    {portfolioData.headline}
                                </p>
                            </article>
                        </section>
                        <section className='projects'>
                            <h2 style={{ color: "white" }}>My recent projects</h2>
                            <br />
                            <article>
                                {portfolioData.projets && portfolioData.projets.length > 0 && (
                                    <>
                                        <img
                                            className='featured-img'
                                            src='https://media.geeksforgeeks.org/wp-content/cdn-uploads/20221123132725/Top-Software-Engineering-Projects-Ideas.png'
                                            alt='slug'
                                        />
                                        <br />
                                        <div className='project-info'>
                                            <h3 style={{ color: "white" }}>
                                                {portfolioData.projets[0].title}
                                            </h3>
                                            <p style={{ color: "white", textAlign: "center" }}>
                                                {portfolioData.projets[0].description}
                                            </p>
                                        </div>
                                    </>
                                )}
                            </article>
                            <Divider style={{ backgroundColor: "#fff" }} />
                            {portfolioData.projets && portfolioData.projets.slice(1).map((item, index) => (
                                <article className='article-item' key={index}>
                                    <div className='left'>
                                        <img
                                            className='featured-img'
                                            src='https://media.geeksforgeeks.org/wp-content/cdn-uploads/20221123132725/Top-Software-Engineering-Projects-Ideas.png'
                                            alt='slug'
                                        />
                                    </div>
                                    <div className='right'>
                                        <br />
                                        <h3 style={{ color: "white" }}>{item.title}</h3>
                                        <p style={{ color: "white", textAlign: "center" }}>{item.description}</p>
                                    </div>
                                </article>
                            ))}
                        </section>
                    </div>
                    <AsideComp />
                </div>
                <FooterComp />
            </div>
        </>
    );
}

export default ShowPortfolio;
