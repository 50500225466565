import React, { useState } from "react";
import { Button, Steps, Modal, notification, Form, Input } from "antd";
import "./ShowPortfolio_steps/portfolio_steps.css";
import PortfolioModal from "./PortfolioModal.js";
import { useNavigate } from "react-router-dom";
import LOGO from "../../assets/images/LOGO.jpg";
import { LeftOutlined } from "@ant-design/icons";
import axios from "axios";
import { useAuth } from "../../AuthenticationContext/AuthContext";
import {useButtonClick} from "../ProfileComponent/ButtonClickContext";
import {savePortfolioData} from "../../Connexion/axiosPortfolio";

const { Step } = Steps;

const steps = [
    { title: "About you", key: "headline" },
    { title: "Skills", key: "skills" },
    { title: "Education", key: "educations" },
    { title: "Projects", key: "projets" },
    { title: "Experience", key: "experiences" },
];

function PortfolioCreation() {
    const { user } = useAuth();
    const [currentStep, setCurrentStep] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState([]);
    const [skills, setSkills] = useState([]);
    const [headline, setHeadline] = useState("");
    const { setIsButtonClicked } = useButtonClick();
    const [allData, setAllData] = useState({
        userId: null,
        headline: "",
        skills: [],
        educations: {},
        projets: {},
        experiences: {},
    });

    const handleOpenModal = () => {
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    // Function to handle adding headline
    const handleAddHeadline = () => {
        setAllData((prevData) => ({ ...prevData, headline }));
        handleCloseModal();
        setCurrentStep((prevStep) => prevStep + 1);
    };

    // ---------------------Skills:------------------
    // Handle add input for skills
    const handleAddInput = () => {
        setSkills([...skills, ""]);
    };

    // Handle change for skills
    const handleChange = (index, value) => {
        const updatedSkills = [...skills];
        updatedSkills[index] = value;
        setSkills(updatedSkills);
    };

    // Handle save for skills
    const handleSave = () => {
        setAllData((prevData) => ({ ...prevData, skills }));
        setSkills([]);
        handleCloseModal();
        setCurrentStep((prevStep) => prevStep + 1);
    };

    // ---------------------Save all data:------------------
    const handleSaveData = async (data) => {
        const userId = localStorage.getItem('userId');

        const hasData = Object.values(allData).some((item) => {
            if (Array.isArray(item)) {
                return item.some((subItem) => subItem && subItem.trim() !== '');
            } else {
                return item && item.trim() !== '';
            }
        });

        if (hasData) {
            const currentStepKey = steps[currentStep].key;
            const newData = { [currentStepKey]: data, userId: user?.id }; // Include userId in newData

            // Save data to localStorage with the corresponding step.key
            localStorage.setItem(currentStepKey, JSON.stringify(newData));

            // Accumulate data from all steps
            setAllData((prevData) => {
                const updatedData = { ...prevData, ...newData };
                console.log("All Data:", updatedData);
                return updatedData;
            });

            // Reset formData to an empty array
            setFormData([]);

            setCurrentStep((prevStep) => prevStep + 1);
            handleCloseModal();
        } else {
            // Show an alert or any other indication that data is required
            alert("Please enter data before proceeding to the next step.");
        }
    };

    const handleBack = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const showCongratulationsNotification = async () => {
        const userId = localStorage.getItem('userId');
        console.log("user id is: ", userId);
        const hasData = Object.values(allData).some((item) => {
            if (Array.isArray(item)) {
                return item.some((subItem) => subItem.trim() !== '');
            } else {
                return item.trim() !== '';
            }
        });
        console.log("The portfolio data that will be saved is: ", allData);
        if (hasData) {
            try {
                // Make a POST request to save the portfolio data
                await savePortfolioData({
                    userId: user?.id,
                    ...allData,
                });

                console.log('Data saved successfully:', allData);
                // Save data to localStorage with the corresponding step.key
                localStorage.setItem('experiences', JSON.stringify(allData));

                // Reset formData to an empty array
                setFormData([]);

                setCurrentStep((prevStep) => prevStep + 1);
                handleCloseModal();

                notification.success({
                    message: "Congratulations!",
                    description: "You have successfully created your portfolio.",
                    placement: "topRight",
                    duration: 5, // Set duration to 0 for the notification to stay until manually closed
                    onClose: (e) => {
                        if (e && e.target && e.target.getAttribute("data-clicked") !== "true") {
                            // Close the page only if the notification is closed and the button is not clicked
                            window.close();
                        }
                    },
                    btn: (
                        <Button type="primary" onClick={() => redirectToPortfolioPage()} data-clicked="true">
                            Show your Portfolio
                        </Button>
                    ),
                });
            } catch (error) {
                console.error('Error saving data:', error);
                // Handle the error accordingly (show an alert, etc.)
            }
        } else {
            alert('Please enter data before proceeding to the next step or user ID not found.');
        }
    };

    const redirectToPortfolioPage = () => {
        navigate("/my-portfolio");
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleReturn = () => {
        navigate("/home");
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handlePortfolioWithIA = () => {

        navigate("/resume-upload");
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsButtonClicked(true);
    };

    return (
        <div className="stepper-component">
            <div className="return-container" onClick={handleReturn}>
                <LeftOutlined style={{ fontSize: 30, marginRight: 7, color: "#0062BD" }} />
                Return
            </div>
            <img className="logo" src={LOGO} />
            <h3>Follow the steps to create your portfolio </h3>
            <div className="app-container">
                <Steps current={currentStep} style={{ marginBottom: "20px" }}>
                    {steps.map((step, index) => (
                        <Step key={index} title={step.title} />
                    ))}
                </Steps>

                <div className="center">
                    <Button onClick={handleOpenModal} style={{ backgroundColor: "#2090F8", color: "white", width: 130, fontWeight: 'bold' }}>Enter Data</Button>

                    <Modal
                        open={modalVisible && currentStep === 0}
                        onCancel={handleCloseModal}
                        title="Add the first section"
                        width={600}
                        footer={[
                            <Button key="back" onClick={handleCloseModal}>
                                Cancel
                            </Button>,
                            <Button key="submit" type="primary" onClick={handleAddHeadline}>
                                Save
                            </Button>,
                        ]}
                    >
                        <label style={{ fontSize: 20, marginBottom: 5, marginLeft: 10 }}>
                            What do you want to write about yourself :
                        </label><br />
                        <input type="text" style={{ width: 550, height: 50, fontSize: 20, textAlign: "center" }} value={headline}
                               onChange={(e) => setHeadline(e.target.value)} />
                    </Modal>

                    <Modal
                        open={modalVisible && currentStep === 1}
                        onCancel={handleCloseModal}
                        title="Add Your Skills"
                        footer={[
                            <Button key="back" onClick={handleCloseModal}>
                                Cancel
                            </Button>,
                            <Button key="addInput" onClick={handleAddInput}>
                                Add
                            </Button>,
                            <Button key="save" type="primary" onClick={handleSave}>
                                Save
                            </Button>,
                        ]}
                    >
                        <Form>
                            {skills.map((skill, index) => (
                                <Form.Item key={index} label={`${index + 1}`}>
                                    <Input
                                        value={skill}
                                        style={{ marginBottom: 7, fontSize: 18 }}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                    />
                                    <br />
                                </Form.Item>
                            ))}
                        </Form>
                    </Modal>

                    {currentStep > 1 && (
                        <PortfolioModal
                            visible={modalVisible}
                            title={currentStep < steps.length ? `Add your ${steps[currentStep]?.title}` : ""}
                            onCancel={handleCloseModal}
                            onSave={handleSaveData}
                            currentStep={currentStep}
                            placeholder={currentStep < steps.length ? `The title of the ${steps[currentStep]?.title}` : ""}
                            steps={steps}
                        />
                    )}

                    <Button
                        disabled={currentStep === 0}
                        style={{ marginRight: 10, marginLeft: 20 }}
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                    <Button
                        type="primary"
                        onClick={showCongratulationsNotification}
                        disabled={currentStep < steps.findIndex(step => step.key === "experiences") + 1}
                        style={{
                            marginLeft: 55,
                            fontWeight: 'bold',
                            width: 130,
                            color: 'white',
                            backgroundColor: currentStep >= steps.findIndex(step => step.key === "experiences") + 1 ? 'green' : 'gray',
                        }}
                    >
                        Submit
                    </Button>
                    <br />
                    <br />
                    <br />
                </div>
                <br />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50 }}>
                <div className='group_line' style={{ fontSize: 30, marginBottom: 20 }}>
                    <div className="line"></div>or<div className="line"></div>
                </div>

                <Button className="ia" onClick={handlePortfolioWithIA}>
                    Generate your portfolio with IA
                </Button>

                <span style={{ color: "white", marginTop: -20, fontSize: 17 }}>
                    If you already have a Resume, you can just use it to generate a portfolio
                </span>
            </div>
        </div>
    );
}
export default PortfolioCreation;
