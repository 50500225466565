import profile from "../../../assets/images/avatar.png";
import {
  FacebookOutlined,
  GithubOutlined,
  LinkedinOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {useAuth} from "../../../AuthenticationContext/AuthContext";
import {Button, message, Popconfirm} from "antd";
import {deletePortfolioData} from "../../../Connexion/axiosPortfolio";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const HeaderComp = () => {
  const { user } = useAuth();
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({
        name: '',
        bio: '',
        imageURL:'',
        linkedin: '',
        twitter: '',
        github: '',
        facebook: '',
    });

    useEffect(() => {
        const fetchAdditionalUserInfo = async () => {
            try {
                const response = await axios.get(`https://gp-backend-production.up.railway.app/profiles/byUserId/${user?.id}`);
                const data = response.data;

                console.log("response data: ", data);
                // Update state with additional user information
                if (data) {
                    setUserInfo((prevUserInfo) => ({
                        ...prevUserInfo,
                        imageURL: data.imageLink || '',
                        linkedin: data.linkedinLink || '',
                        twitter: data.twitterLink || '',
                        github: data.githubLink || '',
                        facebook: data.facebookLink || '',
                    }));
                }
            } catch (error) {
                console.error('Error fetching additional user information from API:', error);
            }
        };

        if (user) {
            setUserInfo({
                name: user.fullName,
                bio: user.profession,
            });

            fetchAdditionalUserInfo();
        }
    }, [user]);

// Use another useEffect to log the updated state
    useEffect(() => {
        console.log("user Information: ", userInfo);
    }, [userInfo]);


    const handleDelete = async () => {
        try {
            console.log(user.id);
            await axios.delete(`https://gp-backend-production.up.railway.app/api/v1/portfolio/delete/byUer/${user.id}`);
            // Handle success, maybe show a success message
            message.success("Portfolio deleted successfully");
            // Additional logic after successful deletion, if needed
        } catch (error) {
            // Handle error, maybe show an error message
            message.error("Failed to delete portfolio");
            console.error("Delete portfolio error", error);
        }
    };

    const handleContactItemClick = (url) => {
        // Navigate to the user profile page
        navigate(url);
    };
  return (
    <header>
      <div className='left'>
        <img src={userInfo.imageURL} alt='' style={{marginLeft:50 ,borderRadius:100}}/>
        <div className='user-infos' style={{marginLeft:80}}>
          <br/>
          <br/>
          <h1>{userInfo.name || 'Default name'}</h1>
          <h3>{userInfo.bio || 'Bio not available'}</h3>
            <div className='contacts'>
                <a className='contact-item' href={userInfo.linkedin}>
                    <LinkedinOutlined />
                </a>
                <a className='contact-item' href={userInfo.twitter}>
                    <TwitterOutlined />
                </a>
                <a className='contact-item' href={userInfo.github}>
                    <GithubOutlined />
                </a>
                <a className='contact-item' href={userInfo.facebook}>
                    <FacebookOutlined />
                </a>
            </div>

        </div>
      </div>
      <div className='right'>
        <Popconfirm
            title={`Are you sure you want to delete this portfolio?`}
            onConfirm={handleDelete}
            okText="Yes"
            cancelText="No"
        >
          <Button
              className="dlt"
              type="danger"
              style={{
                backgroundColor: "white",
                fontSize: 20,
                color: "black",
                marginBottom: 10,
                marginRight: 70,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: 'bold', boxShadow:true
              }}
          >
            <span>X</span>
          </Button>

        </Popconfirm>
      </div>
    </header>
  );
};

export default HeaderComp;
