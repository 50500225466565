import React, { useState } from 'react';
import axios from 'axios';
import './InputPDFComponent.css';
import FormData from 'form-data';
import {
  LeftOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../../AuthenticationContext/AuthContext";
import LOGO from "../../../assets/images/LOGO.jpg";

const InputPDFComponent = () => {
  const [file, setFile] = useState(null);
  const [sourceId, setSourceId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const navigate = useNavigate();

  const { user } = useAuth();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    console.log(selectedFile)
  };

  const addPdfViaFileUpload = async () => {
    try {
        setLoading(true);

      const formData = new FormData();
      formData.append('file', file);

      const options = {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'multipart/form-data',
        },
      };

      const response = await axios.post(
        'https://api.chatpdf.com/v1/sources/add-file',
        formData,
        options
      );

      // Mettre à jour l'état du sourceId
      setSourceId(response.data.sourceId);
    } catch (error) {
      console.error('Erreur lors de l\'ajout du PDF via le téléchargement de fichier:', error.message);
      console.log('Réponse de l\'API (erreur) :', error.response.data);
    } finally {
        setLoading(false); // Définir l'état de chargement sur false après la requête (qu'elle réussisse ou échoue)
    }
  };


  const fetchDataFromChatPDF = async () => {
    try {
        setLoading(true);
      // Ensure that sourceId is available
      if (!sourceId) {
        console.error('sourceId is not available.');
        return;
      }

      const response = await axios.post(
        'https://api.chatpdf.com/v1/chats/message',
        {
          sourceId: sourceId,
          messages: [
            {
              role: 'user',
              content: "Envoyez-moi un message contenant le JSON des informations du CV dans le format suivant : " +
                  "{'headline':'','skills':[],'educations':[{'description':'','title':''}],'projets':[{'description':'','title':''}],'experiences':[{'description':'','title':''}]}",
            },
          ],
        },
        {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
          },
        }
      );

      const contentString = response.data.content;
      console.log(contentString)

      try {
        const firstBracketIndex = contentString.indexOf('{');
        const lastBracketIndex = contentString.lastIndexOf('}');
        const extractedJSON = contentString.slice(firstBracketIndex, lastBracketIndex + 1);
        const contentJSON = JSON.parse(extractedJSON);
        setResult(contentJSON);

        console.log({
          userId: user?.id,
          ...contentJSON,
        })

        // // Storage of the info into the portfolio_db
        // Storage of the info into the portfolio_db
        if (user?.id && contentJSON) {
          try {
            const response = await axios.post('https://gp-backend-production.up.railway.app/api/v1/portfolio/add', {
              userId: user?.id,
              ...contentJSON,
            });
            console.log('Data saved successfully:', contentJSON);
          } catch (error) {
            console.error('Error saving data to portfolio_db:', error.message);
          }
        } else {
          console.error('Required data is missing for saving to portfolio_db.');
        }


        // Log a message before navigating
        console.log('Navigating to /ai-portfolio...');
        navigate('/ai-portfolio', { state: { jsonData: contentJSON } });

      } catch (error) {
        console.error('Erreur lors de l\'extraction du JSON brut:', error.message);
      }

      // const contentJSON = JSON.parse(contentString);
      //setResult(contentJSON);
      
    } catch (error) {
      console.error('Erreur lors de la requête ChatPDF:', error.message);
      console.log('Réponse de l\'API (erreur) :', error.response);
      console.log(error.message);
    } finally {
        setLoading(false);
    }
  };
  const handleReturn = () => {
    navigate("/portfolio-creation");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  return (
    <div className="talents-component">
      <br/>
      <br/>
      <img src={LOGO} className="p6" />
      <div className="return-container" onClick={handleReturn}>
        <LeftOutlined style={{ fontSize: 30, marginRight: 7, color: "#0062BD" }} />
        Return
      </div>

      {sourceId ? (

        <div>
          <button onClick={fetchDataFromChatPDF} disabled={loading}>
            {loading ? <SyncOutlined spin /> : 'Récupérer les données depuis ChatPDF'}
          </button>
          {result && (
            <div>
              <h2>Résultat JSON</h2>
              <pre>{JSON.stringify(result, null, 2)}</pre>
            </div>
          )}
        </div>
      ) : (
        <div style={{marginLeft:300,marginTop:100}}>
          <label style={{ fontSize: 26 ,fontWeight: 'bold', color:"#2090F8"}}>
            Download the PDF file:<br /><br/>
            <input type="file" accept=".pdf" onChange={handleFileChange} />
          </label>
          <br/>
          <br/>
          <br/>
          <button className="addFile" onClick={addPdfViaFileUpload} disabled={loading} style={{backgroundColor:"#2090F8",borderRadius:20,marginLeft:200, fontSize:22}}>
            {loading ? <SyncOutlined spin /> : 'Add the PDF via file upload'}
          </button>
        </div>
      )}
    </div>
  );
};

export default InputPDFComponent;
