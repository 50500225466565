import { Button } from "antd";
import profile from "./avatar1.jpg";
import {
  FacebookOutlined,
  GithubOutlined,
  LinkedinOutlined,
  MessageOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import {useAuth} from "../../../AuthenticationContext/AuthContext";
import {useEffect, useState} from "react";

const HeaderComp = () => {
  const { user } = useAuth();
  const [userInfo, setUserInfo] = useState({
    name: '',
    bio: '',
    // Add more user information as needed
  });

  useEffect(() => {
    if (user) {
      setUserInfo({
        name: user.fullName,
        bio: user.profession
      });
    }
  }, [user]);
  return (
    <header>
      <div className='left'>
        <img src={profile} alt='' style={{marginLeft:50}}/>
        <div className='user-infos' style={{marginLeft:80}}>
          <br/>
          <br/>
          <h1>{userInfo.name || 'Default name'}</h1>
          <h3>{userInfo.bio || 'Bio not available'}</h3>
          <div className='contacts'>
            <div className='contact-item'>
              <LinkedinOutlined />
            </div>
            <div className='contact-item'>
              <TwitterOutlined />
            </div>
            <div className='contact-item'>
              <GithubOutlined />
            </div>
            <div className='contact-item'>
              <FacebookOutlined />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderComp;
