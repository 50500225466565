const FooterComp = () => {
  return (
    <>
      <footer style={{height:70}}>
        <p style={{color:"white" , fontSize:25, marginTop:3}}>
          Made by the team NEWS Planet
        </p>
          <br/>
          <br/>
      </footer>
    </>
  );
};

export default FooterComp;
