import { Button, Divider } from "antd";
import './portfolio.css';
import HeaderComp from "./HeaderComp";
import FooterComp from "./FooterComp";
import AsideComp from "./AsideComp";
import { useState } from "react";
import { useLocation } from 'react-router-dom';

function ShowPortfolioAi() {
    const location = useLocation();
    const jsonData = location.state && location.state.jsonData;

    console.log(jsonData);

    // Extracting data from jsonData or using default values
    const { headline, educations, projets, experiences } = jsonData || {};
    const projectsData = projets || [];

    return (
        <>
            <div className='main'>
                <HeaderComp />
                <div className='body-container'>
                    <div className='left'>
                        <section className='about'>
                            <article>
                                <h2 style={{ color: "white" }}>About me</h2>
                                <p style={{ color: "white", textAlign: "center", fontSize: 24 }}>
                                    {/* Use jsonData values or fallback text */}
                                    {headline || "Default about me text"}
                                </p>
                            </article>
                        </section>
                        <section className='projects'>
                            <h2 style={{ color: "white" }}>My recent projects</h2>
                            <br />
                            {/* Display the first project separately */}
                            {projets && projets.length > 0 && (
                                <article>
                                    <img
                                        className='featured-img'
                                        src='https://media.geeksforgeeks.org/wp-content/cdn-uploads/20221123132725/Top-Software-Engineering-Projects-Ideas.png'
                                        alt='slug'
                                        // style={{height:200}}
                                    />
                                    <br />
                                    <div className='project-info'>
                                        <h3 style={{ color: "white" }}>{projets[0].title}</h3>
                                        <p style={{ color: "white", textAlign: "center" }}>
                                            {/* Use jsonData values or fallback text */}
                                            {projets[0].description || "Default project description"}
                                        </p>
                                    </div>
                                </article>
                            )}
                            <Divider style={{ backgroundColor: "#fff" }} />
                            {/* Map over the remaining projects */}
                            {projets && projets.slice(1).map((project, index) => (
                                <article className='article-item' key={index}>
                                    <div className='left'>
                                        <img
                                            className='featured-img'
                                            src='https://media.geeksforgeeks.org/wp-content/cdn-uploads/20221123132725/Top-Software-Engineering-Projects-Ideas.png'
                                            alt='slug'
                                        />
                                    </div>
                                    <div className='right'>
                                        <br />
                                        <h3 style={{ color: "white" }}>{project.title}</h3>
                                        <p style={{ color: "white", textAlign: "center" }}>{project.description}</p>
                                    </div>
                                </article>
                            ))}
                        </section>
                    </div>
                    <AsideComp jsonData={jsonData} />
                </div>
                <FooterComp />
            </div>
        </>
    );
}

export default ShowPortfolioAi;
