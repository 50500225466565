const AsideComp = ({ jsonData }) => {
  const { educations, experiences, skills } = jsonData || {};
  console.log(educations);
  console.log(experiences);
  console.log(skills);

  return (
      <>
        <aside>
          {/* Skills Section */}
          <div className='section'>
            <h2 style={{ color: "white" }}>Skills</h2>
            <p style={{ color: "white" }}>
              {skills &&
                  skills.length > 0 &&
                  skills.map((skill, index) => <li key={index}>{skill}</li>)}
            </p>
          </div>

          {/* Education Section */}
          <div className='section'>
            <h2 style={{ color: "white" }}>Education</h2>
            <br />
            {educations &&
                educations.length > 0 &&
                educations.map((item, index) => (
                    <div className='education-item' key={index}>
                      <h3 style={{ color: "white", textAlign: "left", fontSize: 26 }}>
                        - {item.title}
                      </h3>
                      <span style={{ color: "white" }}>{item.name}</span>
                    </div>
                ))}
          </div>

          {/* Experiences Section */}
          <div className='section'>
            <h2 style={{ color: "white" }}>Experiences</h2>
            <br />
            {experiences &&
                experiences.length > 0 &&
                experiences.map((item) => (
                    <article key={item.id}>
                      <h3 style={{ color: "white", textAlign: "left" }}>
                        - {item.title}
                      </h3>
                      <p style={{ color: "white" }}>{item.description}</p>
                    </article>
                ))}
          </div>
        </aside>
      </>
  );
};

export default AsideComp;
