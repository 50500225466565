
import axios from "axios";
import {useAuth} from "../../../AuthenticationContext/AuthContext";
import {useEffect, useState} from "react";  // Import axios

const AsideComp = () => {
  const { user } = useAuth();
  const [portfolioData, setPortfolioData] = useState({
    headline: "",
    skills: [],
    educations: [],
    experiences: [],
  });

  useEffect(() => {
    const userId = user ? user.id : null; // Use userId from the authenticated user if available
    axios.get(`https://gp-backend-production.up.railway.app/api/v1/portfolio/byUser/${userId}`)
        .then(response => {
          const data = response.data;
          setPortfolioData(data);
          console.log("data from API: ", data);
        })
        .catch(error => {
          console.error('Error fetching portfolio data:', error);
        });
  }, []); // Empty dependency array to run once on component mount

  return (
      <>
        <aside>
          {/* Skills Section */}
          <div className='section'>
            <h2 style={{ color: "white" }}>Skills</h2><br/>
            <ul style={{ color: "white", listStyleType: "none", padding: 0 }}>
              {portfolioData.skills.map((skill, index) => (
                  <li style={{fontSize:23}} key={index}>- {skill}</li>
              ))}
            </ul>
          </div>

          {/* Educations Section */}
          <div className='section'>
            <h2 style={{ color: "white" }}>Education</h2><br />
            {portfolioData.educations.map((item, index) => (
                <div className='education-item' key={index}>
                  <h3 style={{ color: "white", textAlign: "left", fontSize: 26 }}>- {item.title}</h3>
                  <span style={{ color: "white",fontSize: 23  }}>{item.description}</span>
                  <br/>
                  <br/>
                </div>
            ))}
          </div>

          {/* Experiences Section */}
          <div className='section'>
            <h2 style={{ color: "white" }}>Experiences</h2><br />
            {portfolioData.experiences.map((item) => (
                <article key={item.id}>
                  <h3 style={{ color: "white", textAlign: "left" }}>- {item.title}</h3>
                  <p style={{ color: "white" }}>{item.description}</p>
                </article>
            ))}
          </div>
        </aside>
      </>
  );
};

export default AsideComp;
