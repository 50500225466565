import axios from "axios";

const API_URL = "https://gp-backend-production.up.railway.app/api/v1";

const fetchPortfolioData = async (userId) => {
    try {
        const response = await axios.get(`${API_URL}/portfolio/byUser/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const savePortfolioData = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/portfolio/add`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deletePortfolioData = async (userId) => {
    try {
        const response = await axios.delete(`${API_URL}/portfolio/delete/byUser/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export { fetchPortfolioData, savePortfolioData, deletePortfolioData };
