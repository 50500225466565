import React, { useEffect, useState } from 'react';
import './ProfilePage.css';
import { EditOutlined, FileOutlined, LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthenticationContext/AuthContext";
import PortfolioCreation from "../PortfolioComponent/PortfolioCreation";
import { useButtonClick } from "./ButtonClickContext";
import axios from "axios";
import { Modal, Input } from 'antd';

const ProfilePage = () => {
    const { user } = useAuth();
    const [resume, setResume] = useState(null);
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({
        name: '',
        bio: '',
    });

    const [imageUrl, setImageUrl] = useState('');

    const [informationModalVisible, setInformationModalVisible] = useState(false);
    const [cvPath, setCvPath] = useState('');
    const [githubUrl, setGithubUrl] = useState('');
    const [linkedinUrl, setLinkedinUrl] = useState('');
    const [facebookUrl, setFacebookUrl] = useState('');
    const [twitterUrl, setTwitterUrl] = useState('');

    useEffect(() => {
        if (user) {
            setUserInfo({
                name: user.fullName,
                bio: user.profession
            });
        }
    }, [user]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("User ID:", user?.id);
                const response = await axios.get(`https://gp-backend-production.up.railway.app/profiles/byUserId/${user?.id}`);
                const data = response.data;
                console.log(response.data);
                setImageUrl(data.imageLink);
                setResume(data.cvLink);

            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        };

        fetchData();
    }, [user?.id]);


    const handleSaveInformation = async () => {
        try {
            // Make a POST request to the API to save the information
            const response = await axios.post('https://gp-backend-production.up.railway.app/profiles/create', {
                userId: user.id,
                imageLink: imageUrl, // Update the field name
                cvLink: cvPath, // Update the field name
                githubLink: githubUrl,
                linkedInLink: linkedinUrl,
                twitterInLink: twitterUrl, // Update the field name
                facebookInLink: facebookUrl, // Update the field name
            },
            { headers: { 'Content-Type': 'application/json' } }
            );

            console.log('Information saved successfully:', response.data);
        } catch (error) {
            console.error('Error saving information:', error.response || error.message);
        }
    };


    const handleLoadInformationClick = () => {
        setInformationModalVisible(true);
    };





    const handleReturn = () => {
        navigate("/home");
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    const seePortfolio = () => {
        console.log("Navigation to the portfolio done with the steps");
        navigate("/my-portfolio");
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <div className="profile-page">
            <div className="return-container" onClick={handleReturn}>
                <LeftOutlined style={{ fontSize: 30, marginRight: 7, color: "#0062BD" }} />
                Return
            </div>
            <br />
            <br />
            <br />
            <br />
            <div className="fbox">
                <div className="profile-header">
                    <div className="profile-image-container">
                        {imageUrl ? <img src={imageUrl} alt="Profile" className="profile-image" /> : <div className="default-profile-image">No Photo</div>}
                    </div>
                    <div className="user-info">
                        <h2>{userInfo.name || 'John Doe'}</h2>
                        <p style={{ color: "white", fontSize: 30 }}>{userInfo.bio || 'Bio not available'}</p>
                    </div>
                </div>
            </div>
            <div className="b-container">
                <div className="cv-container">
                    <button className="cv-b" onClick={handleLoadInformationClick}>
                        <PlusOutlined style={{ fontSize: 22, marginRight: 10 }} /> Load your information
                    </button>
                    <label htmlFor="file-input">
                        <input
                            type="file"
                            id="file-input"
                            style={{ display: "none" }}
                        />
                    </label>
                    {resume && (
                        <div className="file-preview">
                            <p style={{ fontSize: 25, marginLeft: -50, marginTop: -4 }}>Selected Resume: {resume}</p>
                        </div>
                    )}
                </div>

                <div className="portfolio-container">
                    <button className="portfolio-b" onClick={seePortfolio}>
                        <FileOutlined style={{ fontSize: 22, marginRight: 10 }} /> View your portfolio
                    </button>
                </div>
            </div>



            {/* Information Modal */}
            <Modal
                title="Load Your Information"
                visible={informationModalVisible}
                onOk={() => {
                    setInformationModalVisible(false);
                    handleSaveInformation(); // Call the function to save the information
                }}
                onCancel={() => setInformationModalVisible(false)}
            >
                <br/>
                <Input placeholder="Image URL" onChange={(e) => setImageUrl(e.target.value)}/><br/>
                <span style={{fontSize:10,color:"gray"}}>Format : http://xxxx</span><br/><br/>
                <Input placeholder="CV Path" onChange={(e) => setCvPath(e.target.value)} /><br/><br/>
                <Input placeholder="GitHub URL" onChange={(e) => setGithubUrl(e.target.value)} /><br/><br/>
                <Input placeholder="LinkedIn URL" onChange={(e) => setLinkedinUrl(e.target.value)} /><br/><br/>
                <Input placeholder="Facebook URL" onChange={(e) => setFacebookUrl(e.target.value)} /><br/><br/>
                <Input placeholder="Twitter URL" onChange={(e) => setTwitterUrl(e.target.value)} /><br/><br/>
            </Modal>

            <br />
        </div>
    );
};

export default ProfilePage;
