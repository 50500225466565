import "./HeaderComponent.css";
import React, { useState } from 'react';
import logo1 from "../../assets/images/logo1.png";
import {Button, Menu, Modal, Dropdown, message} from 'antd';
import privateRoutes from "../../privateRoutes";
import {useNavigate} from "react-router-dom";
import { useAuth } from "../../AuthenticationContext/AuthContext";
import {LogoutOutlined, UserOutlined} from "@ant-design/icons";
function getItem(label, key, path) {
    return {
        key,
        label,
        path
    };
}
const MenuItems = [
    getItem('Home', '1',privateRoutes.home.path),
    getItem('About us','2',privateRoutes.aboutUs.path),
    getItem('Talent Pool','3',privateRoutes.talentPool.path)
];

function HeaderComponent({ defaultSelectedKeys }) {
    const [selectedKeys, setSelectedKeys] = useState(defaultSelectedKeys);
    const navigate = useNavigate();
    const { user, logout } = useAuth();
    console.log('User in HeaderComponent:', user);

    const signInPage=() => {
        navigate("/login");
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    const handleMenuClick = ({ key }) => {
        setSelectedKeys([key]);
    };
    const success = () => {
        message.open({
            type: 'success',
            content: 'Log out successful !! ',
        });
    };
    const handleSeeProfile = () => {
        navigate("/my-profile");
        console.log("After navigation");
    };
    const handleLogout = () => {
        success();
        logout();
    };

    const handleDropdownMenuClick = ({ key }) => {
        // Handle dropdown menu clicks here
        if (key === 'Show Profile') {
            handleSeeProfile();
        } else if (key === 'LOG OUT') {
            handleLogout();
        }
    };

    const items = [
        {
            label: 'Show Profile',
            icon: <UserOutlined />,
        },
        {
            label: 'LOG OUT',
            icon: <LogoutOutlined />,
        },
    ];

    const dropdownMenu = (
        <Menu onClick={handleDropdownMenuClick} style={{ fontSize: 20, width: 250, marginLeft: 70 , padding: '10px'}}>
            {items.map((item) => (
                <Menu.Item key={item.label} icon={item.icon} style={{ fontSize: 17 , fontWeight: 'bold' }}>
                    {item.label}
                </Menu.Item>
            ))}
        </Menu>
    );

    const dropdownProps = {
        overlay: dropdownMenu,
        placement: "bottomRight",
        arrow: true,
    };





    return (
        <div className="header-container" justify="center" align="middle">

            <img src={logo1} alt="Logo" className="logo_gp"/>

            <Menu mode="horizontal" className="menu_items"
                  selectedKeys={selectedKeys}
                  onClick={handleMenuClick}
            >
                {MenuItems.map(item => (
                    <Menu.Item key={item.key}>
                        <a href={item.path} className="menu-link">{item.label}</a>
                    </Menu.Item>
                ))}
            </Menu>
            <div className="mt-4 ">
                {user ? (
                    <>
                        <Dropdown {...dropdownProps}>
                            <p className="user-link" style={{ textDecoration: 'none', color: '#0062BD', fontSize: 25,border: '1px solid black', padding: '15px',marginTop:-10  }}>
                            {user.fullName}
                                </p>
                        </Dropdown>
                    </>
                ) : (
                    <Button onClick={signInPage} className="sign-in-button">Sign in</Button>
                )}
            </div>

        </div>
    );
};

export default HeaderComponent;
