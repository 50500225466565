
import React, { createContext, useContext, useState } from 'react';

const ButtonClickContext = createContext();

export const ButtonClickProvider = ({ children }) => {
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    return (
        <ButtonClickContext.Provider value={{ isButtonClicked, setIsButtonClicked }}>
            {children}
        </ButtonClickContext.Provider>
    );
};

export const useButtonClick = () => {
    const context = useContext(ButtonClickContext);
    if (!context) {
        throw new Error('useButtonClick must be used within a ButtonClickProvider');
    }
    return context;
};
