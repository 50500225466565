import "./TalentsComponent.css";
import React, { useEffect, useState } from "react";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import { Button, Card, Col, Input, Row } from "antd";
import LOGO from "../../assets/images/LOGO.jpg";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {useAuth} from "../../AuthenticationContext/AuthContext";

const UserCard = ({ user }) => (
    <Col span={8} key={user.id} style={{ marginBottom: 16 }}>
        <Card
            title={
                <span style={{ color: '#1686EE', display: 'block', textAlign: 'center' }}>
          {`${user.fullName}`} <br /> {`${user.profession}`}
        </span>
            }
            bordered={false}
            className="user-card"
            style={{ width: '80%', minHeight: '200px' , marginLeft:50}}
        >
            {/* Add any additional user details or decorations here */}
            <p>Email: {user.email}</p>
        </Card>
    </Col>
);


function TalentsComponent() {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const { user, logout } = useAuth();

    const handlePortfolio = () => {
        if (user) {
            navigate("/portfolio-creation");
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            navigate("/login");
        }
    };

    const handleSearch = (query) => {
        const results = users.filter(
            (user) =>
                (user.fullName?.toLowerCase().includes(query.toLowerCase()) || false) ||
                (user.profession?.toLowerCase().includes(query.toLowerCase()) || false)
        );
        setSearchResults(results);
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://gp-backend-production.up.railway.app/api/v1/users", {});
                const data = await response.json();
                setUsers(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="talents-component">
            <HeaderComponent defaultSelectedKeys={["3"]} />

            <img src={LOGO} className="p6" />

            <div className="ta">
                <Row>
                    <Col style={{ width: 450, marginLeft: 200, marginTop: 35 }}>
                        <h4 style={{ fontSize: 30 }}>
                            <span className="hl">Find talent.</span> Over 1 000 000 portfolios and 5 million projects.
                        </h4>
                        <br />
                        <br />
                    </Col>

                    <Button
                        className="start-btn"
                        style={{
                            marginTop: 100,
                            fontSize: 30,
                            height: 60,
                            width: 350,
                            boxShadow: 5,
                            fontFamily: "Josefin Sans",
                        }}
                        onClick={handlePortfolio}
                    >
                        Create your portfolio
                    </Button>
                </Row>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Input
                        placeholder="Search..."
                        bordered={false}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                            handleSearch(e.target.value);
                        }}
                        style={{
                            borderBottom: "1px solid white", // Add an underline
                            color: "white",
                            width: 900,
                            marginLeft: 110,
                            marginTop: 50,
                            fontSize: 30,
                        }}
                        className="custom-input"
                    />
                    <SearchOutlined style={{ color: "white", marginLeft: -40, fontSize: "30px", marginTop: 30 }}
                    />
                </div>
            </div>
            <div className="user-list" style={{ marginTop: 90 }}>
                <Row gutter={[16, 16]} justify="start">
                    {(searchQuery ? searchResults : users).map((user) => (
                        <UserCard key={user.id} user={user}  />
                    ))}
                </Row>
            </div>
        </div>
    );
}

export default TalentsComponent;
