import React, {createContext, useContext, useEffect, useState} from 'react';
import apiUser from "../Connexion/AxiosUserMS";
import { Link, useNavigate } from "react-router-dom";


export const useAuth = () => useContext(AuthContext);

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            try {
                setUser(JSON.parse(storedUser));
            } catch (error) {
                console.error("Error parsing user data from localStorage", error);
            }
        }
    }, []);

    const login = async (email, password) => {
        try {
            const response = await apiUser.post('/signin', { email, password });

            //const userData = jwt_decode(response.data.token);
            //console.log('Decoded User Data:', userData);

            const userData = response.data;
            console.log('API Response/User Data:',userData);

            if (!userData) {
                console.error('Error during login: User data is undefined or null');
                throw new Error('Invalid email or password');
            }

            setUser({
                id: userData.id,
                email: userData.email,
                token: userData.token,
                fullName: userData.fullName,
                profession: userData.profession
            });

            localStorage.setItem('user', JSON.stringify(userData));
            navigate("/home");
        } catch (error) {
            console.error('Error during login:', error);
            throw new Error('Invalid email or password');
        }
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem('userToken');
        localStorage.removeItem('user');
        navigate('/');
    };

    const value = {
        user,
        login,
        logout,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

